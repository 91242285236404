import { ControllerParams } from '@wix/yoshi-flow-editor';
import { PricingPlansAPI } from '@wix/wix-vod-pricing-plans-api';
import { dashify } from '@wix/wix-vod-shared/common';

export type PricingPlanHandlers = {
  requestPricingPlanPaymentInfo: (channelId: string, userId: string) => void;
};

export type PricingPlanState = {
  isPricingPlanAccessInfoLoaded: boolean;
  hasAccessToChannelByPricingPlan: boolean;
};

export const getInitialPricingPlanState = (
  context: ControllerParams,
): PricingPlanState => {
  const loggedIn = Boolean(
    context.controllerConfig.wixCodeApi.user.currentUser?.loggedIn,
  );

  return {
    isPricingPlanAccessInfoLoaded: !loggedIn,
    hasAccessToChannelByPricingPlan: false,
  };
};

export function createPricingPlanHandlers(
  setState: (state: PricingPlanState) => void,
  context: ControllerParams,
): PricingPlanHandlers {
  const {
    flowAPI: { httpClient, reportError },
  } = context;

  return {
    requestPricingPlanPaymentInfo: async (
      channelId: string,
      userId: string,
    ) => {
      try {
        const pricingPlanService = new PricingPlansAPI(httpClient);
        const hasAccessToChannelByPricingPlan =
          await pricingPlanService.hasAccessToChannel(
            dashify(channelId) as string,
            userId,
          );

        setState({
          isPricingPlanAccessInfoLoaded: true,
          hasAccessToChannelByPricingPlan,
        });
      } catch (e) {
        reportError(
          new Error('Error in requestPricingPlanPaymentInfo', { cause: e }),
        );

        setState({
          isPricingPlanAccessInfoLoaded: true,
          hasAccessToChannelByPricingPlan: false,
        });
      }
    },
  };
}
